import {AnyAction} from 'redux'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {NAVIGATE} from '../actions/navigation'
import {Navigation} from '../types'

const defaultState: Navigation = {
  sectionPath: '',
  slug: '',
  route: DETAILS_ROUTE.DETAILS,
}

export const navigation = (state = defaultState, action: AnyAction): Navigation => {
  switch (action.type) {
    case NAVIGATE:
      return {...state, ...action.payload}
    default:
      return state
  }
}
