import {AnyAction} from 'redux'
import {PoliciesState} from '../types'
import {TOGGLE_AGREE_POLICIES, GET_POLICIES, RESET_POLICIES} from '../actions/policies'

const defaultState: PoliciesState = {
  agreed: false,
  showAlert: false,
  policies: [],
  policyAgreementToken: null,
}

export const policies = (state: PoliciesState = defaultState, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_AGREE_POLICIES:
      return {
        ...state,
        agreed: !state.agreed,
      }
    case GET_POLICIES.REQUEST:
      return {
        ...state,
        showAlert: action.args[1].showAlert,
        agreed: false,
      }
    case GET_POLICIES.SUCCESS:
      return {
        ...state,
        ...action.payload,
        agreed: false,
        showAlert: action.args[1].showAlert,
      }
    case RESET_POLICIES:
      return {
        ...state,
        agreed: false,
        showAlert: false,
      }
    default:
      return state
  }
}
