import {createReducer} from '@reduxjs/toolkit'
import {ScheduleState} from '../types'

const defaultState = {
  facets: {},
  items: [],
  limit: 0,
  offset: 0,
  total: 0,
} as ScheduleState

export const schedule = createReducer(defaultState, () => {})
