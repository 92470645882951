import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'

type FedopsLoggerConfig = string | {appId: string; widgetId: string}

enum Interaction {
  CreateReservation = 'create-reservation',
  Checkout = 'checkout',
}

export const createFedopsLogger = (controller: IWidgetControllerConfig, config: FedopsLoggerConfig) => {
  const {fedOpsLoggerFactory} = controller.platformAPIs
  let isFedopsReport = true
  const fedopsLogger = fedOpsLoggerFactory.getLoggerForWidget(config)

  fedopsLogger.appLoadStarted()

  const onSSRPageReady = () => {
    fedopsLogger.appLoaded()
  }

  const onAppLoaded = () => {
    if (isFedopsReport) {
      fedopsLogger.appLoaded()
      isFedopsReport = false
    }
  }

  const createReservationStarted = () => fedopsLogger.interactionStarted(Interaction.CreateReservation)
  const createReservationEnded = () => fedopsLogger.interactionEnded(Interaction.CreateReservation)

  const checkoutStarted = () => fedopsLogger.interactionStarted(Interaction.Checkout)
  const checkoutEnded = () => fedopsLogger.interactionEnded(Interaction.Checkout)

  return {
    onSSRPageReady,
    onAppLoaded,
    createReservationStarted,
    createReservationEnded,
    checkoutStarted,
    checkoutEnded,
  }
}
