import {
  BI_ORIGINS,
  ChangeRsvpModalMode,
  MembersModal,
  getEventId,
  isNoResponseEnabled,
} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {isPreview} from '../../commons/selectors/environment'
import {openDialogModal, openModal} from '../../commons/services/modal'
import {getEventRsvp} from '../selectors/event'
import {DetailedEvent, GetState} from '../types/state'

export const DELETE_RSVP = createActions('DELETE_RSVP')
export const CANCEL_RSVP = createActions('CANCEL_RSVP')

export const cancelRsvp = (event: DetailedEvent) => (dispatch: Function, getState: GetState) => {
  const eventId = getEventId(event.event)
  const state = getState()

  if (isPreview(state)) {
    return dispatch(
      openModal({
        type: MembersModal.NO_CANCEL_RSVP,
        params: {eventId, origin: BI_ORIGINS.MEMBERS_AREA},
      }),
    )
  } else {
    return dispatch(
      openDialogModal({
        type: MembersModal.UPDATE_RSVP,
        params: {eventId, mode: ChangeRsvpModalMode.MEMBERS_PAGE, origin: BI_ORIGINS.MEMBERS_AREA},
        onConfirm: () => dispatch(onConfirmUpdateRsvp(event) as any),
      }),
    )
  }
}

const onConfirmUpdateRsvp = (event: DetailedEvent) => {
  if (isNoResponseEnabled(event.event)) {
    return callAPI(CANCEL_RSVP, {
      eventId: getEventId(event.event),
      rsvpId: getEventRsvp(event).id,
    })
  } else {
    return callAPI(DELETE_RSVP, {
      eventId: getEventId(event.event),
      rsvpId: getEventRsvp(event).id,
    })
  }
}
