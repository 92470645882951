import {getEventId} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {isDemoEvent} from '../selectors/event'
import {GetState} from '../types'

export const SHARE_EVENT = 'SHARE_EVENT'
export const GET_EVENT = createActions('GET_EVENT')
export const GET_MEMBERS = createActions('GET_MEMBERS')

export const getEvent = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  if (!isDemoEvent(state)) {
    return dispatch(callAPI(GET_EVENT, getEventId(state.event)))
  }
}

export const getMembers = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  if (state.membersAreaEnabled && getEventId(state.event) && !isDemoEvent(state)) {
    return dispatch(callAPI(GET_MEMBERS, getEventId(state.event)))
  }
}

export const shareEvent = (buttonName: string, origin: string) => ({type: SHARE_EVENT, payload: {buttonName, origin}})
