import {
  IAppData,
  IPlatformAPI,
  IWidgetControllerConfig,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types'
import {
  EVENTS_DETAILS_PAGE_ID,
  EVENTS_MEMBERS_WIDGET_ID,
  EVENTS_WIDGET_ID,
  EVENTS_SCHEDULE_PAGE_ID,
} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {getExperimentsByScope} from './commons/utils/experiments'
import {createDetailsPageController} from './details-page/controllers'
import {createMembersPageController as createMembersAppController} from './members-page/controllers/members-page'
import {createWidgetController} from './widget/controllers'

let experiments: ExperimentsBag

export const initAppForPage = async (initParams: IAppData, apis: IPlatformAPI, wixCodeApi: IWixAPI) => {
  const [exp] = await Promise.all([getExperimentsByScope(wixCodeApi, 'wix-events-ui')])
  experiments = {
    ...exp,
  }
}

export const createControllers = (controllerConfigs: IWidgetControllerConfig[], controllerInstances?) =>
  controllerConfigs.map(c => {
    switch (c.type) {
      case EVENTS_WIDGET_ID:
        return createWidgetController(c, experiments)
      case EVENTS_MEMBERS_WIDGET_ID:
        return createMembersAppController(c, experiments)
      case EVENTS_DETAILS_PAGE_ID:
        return createDetailsPageController(c, experiments)
      case EVENTS_SCHEDULE_PAGE_ID:
        return controllerInstances[c.type].default({
          controllerConfig: c,
          appData: {
            __prepopulated: {
              experiments,
              biLogger: undefined,
            },
          },
        })
      default:
        return undefined
    }
  })
