import {EventDetailsImageRatio, EventDetailsButtonStyle, HEADER_LAYOUT} from '@wix/wix-events-commons-statics'
import {State} from '../types'
import c from '../components/classnames.scss'
import {isDemoEvent} from './event'
import {isMemberEventRsvpReady} from './member-rsvp'

export const isShortDateLocationVisible = (settings: DetailsPageComponentSettings) => settings.shortDateLocationVisible

export const isDescriptionEnabled = (settings: DetailsPageComponentSettings) => settings.descriptionVisible

export const getImagePosition = (settings: DetailsPageComponentSettings) => settings.imagePosition

export const getImageRatio = (settings: DetailsPageComponentSettings) =>
  settings.headerLayout === HEADER_LAYOUT.SIDE_BY_SIDE ? EventDetailsImageRatio['1:1'] : settings.imageRatio

export const getImageOpacity = (settings: DetailsPageComponentSettings) => settings.imageOpacity

export const getButtonStyle = (settings: DetailsPageComponentSettings) => settings.buttonStyle

export const isSquareImage = (settings: DetailsPageComponentSettings) =>
  settings.imageRatio === EventDetailsImageRatio['1:1']

export const getContentAlignment = (settings: DetailsPageComponentSettings) => settings.contentAlignment

export const getHeaderAlignment = (settings: DetailsPageComponentSettings) => settings.headerAlignment

export const getFormButtonStyle = (settings: DetailsPageComponentSettings) => settings.formButtonStyle

export const getTexts = (settings: DetailsPageComponentSettings) => settings.texts

export const isSocialShareVisible = (settings: DetailsPageComponentSettings) => settings.socialShareVisible

export const isScheduleVisible = (settings: DetailsPageComponentSettings) => settings.scheduleVisible

export const isRsvpButtonEnabled = (settings: DetailsPageComponentSettings) => settings.rsvpButtonVisible

export const isMembersAvatarsEnabled = (settings: DetailsPageComponentSettings) => settings.membersVisible

export const isAboutSectionEnabled = (settings: DetailsPageComponentSettings) => settings.aboutSectionVisible

export const isMapEnabled = (settings: DetailsPageComponentSettings) => settings.mapVisible

export const isMembershipOffersVisible = (settings: DetailsPageComponentSettings) => settings.membershipsVisible

export const isRsvpButtonVisible = (state: State) =>
  isRsvpButtonEnabled(state.component.settings) && (isDemoEvent(state) || isMemberEventRsvpReady(state))

export const isRoundedButton = (buttonStyle: EventDetailsButtonStyle) =>
  [EventDetailsButtonStyle.FULL_ROUNDED, EventDetailsButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

export const isHollowButton = (buttonStyle: EventDetailsButtonStyle) =>
  [EventDetailsButtonStyle.HOLLOW, EventDetailsButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

export const isFullButton = (buttonStyle: EventDetailsButtonStyle) =>
  [EventDetailsButtonStyle.FULL, EventDetailsButtonStyle.FULL_ROUNDED].includes(buttonStyle)

export const getFormClassName = (buttonStyle: EventDetailsButtonStyle) =>
  isHollowButton(buttonStyle) ? c.formWithHollowButton : c.formWithFullButton

export const getText = (key: Texts, settings: DetailsPageComponentSettings) => settings.texts[key] || settings[key]

export const getHeaderLayout = (settings: DetailsPageComponentSettings) => settings.headerLayout

export const getHeaderImageAlignment = (settings: DetailsPageComponentSettings) => settings.headerImageAlignment

export const getHeaderBorderWidth = (settings: DetailsPageComponentSettings) => settings.headerBorderWidth
