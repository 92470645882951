import _ from 'lodash'
import {AnyAction} from 'redux'
import {EventDetailsImageRatio} from '@wix/wix-events-commons-statics'
import {UPDATE_COMPONENT_SETTINGS_DRAFT, UPDATE_STYLE_PARAMS} from '../actions/component'
import {DetailsPageComponent} from '../types'

const defaultState: DetailsPageComponent = {
  id: null,
  settings: {} as DetailsPageComponentSettings,
}

export const component = (state = defaultState, action: AnyAction): DetailsPageComponent => {
  switch (action.type) {
    case UPDATE_STYLE_PARAMS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
      }
    case UPDATE_COMPONENT_SETTINGS_DRAFT.REQUEST:
      return {
        ...state,
        settings: ensureEnumeratedCompSettings(mergeCompSettings(action.args[0], state.settings)),
      }
    default:
      return state
  }
}

export const mergeCompSettings = (
  newSettings: Partial<DetailsPageComponentSettings>,
  oldSettings: Partial<DetailsPageComponentSettings>,
): DetailsPageComponentSettings => {
  const keys = [
    'aboutTitleText',
    'readLessText',
    'readMoreText',
    'rsvpButtonText',
    'shareTitleText',
    'ticketsTitleText',
    'timeAndLocationTitleText',
    'scheduleTitleText',
  ]
  const oldTexts = _.pick(oldSettings, keys)
  const newTexts = _.pick(newSettings, keys)
  oldSettings = _.omit(oldSettings, keys)
  newSettings = _.omit(newSettings, keys)

  const mergedSettings = {
    ...oldSettings,
    ...newSettings,
    texts: {
      ...oldTexts,
      ...oldSettings.texts,
      ...newTexts,
      ...newSettings.texts,
    },
  } as DetailsPageComponentSettings

  return mergedSettings
}

const ensureEnumeratedCompSettings = (settings: DetailsPageComponentSettings): DetailsPageComponentSettings => ({
  ...settings,
  imageRatio:
    typeof settings.imageRatio === 'string'
      ? EventDetailsImageRatio[settings.imageRatio as string]
      : settings.imageRatio,
})
